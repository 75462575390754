import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';

import { METADATA } from '/src/globals';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const Resources = (props) => {

  const [fadeActive, setFadeDelay] = useState(false);

  useEffect(() => {
    if (fadeActive === false) {
      setTimeout(() => setFadeDelay(true), 1000);
    }
  }, [fadeActive]);

  //////
  
  return ( <>

    <Helmet>
      <title>Resources{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>

    
    <PageWrapper>

      <Typography variant="h1">
        Resources
      </Typography>

      <Typography variant="body1">
        This contains a number of additional resources that have been helpful while working with this project template.
      </Typography>
      
      <ul>
        <Typography variant="body1">
          <li><Link to="https://cssinjs.org/jss-syntax">JSS Syntax</Link></li>
          <li><Link to="https://material.io/components/buttons">Material Design</Link></li>
          <li><Link to="https://www.gatsbyjs.com/docs/how-to/local-development/troubleshooting-common-errors/">Troubleshooting Gatsby</Link></li>
          <li><Link to="https://www.gatsbyjs.com/contributing/community/#where-to-get-support">Gatsby Community Forums</Link></li>
          <li><Link to="https://www.rodrigoplp.com/blog/passing-data-between-pages-in-gatsbyjs">Passing data between pages</Link></li>
          <li><Link to="https://reach.tech/router/">Reach Router documentation</Link></li>
          <li><Link to="https://blog.logrocket.com/going-offline-in-gatsby/">Supporting offline</Link></li>
        </Typography>
      </ul>


      <DemoNavigation/>

    </PageWrapper>

  </> )

}

Resources.propTypes = propTypes;
export default Resources;
